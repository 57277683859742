import {
  addDays,
  addHours,
  addMinutes,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  endOfDay,
  format,
  getDay,
  isBefore,
  isEqual,
  isSameDay,
  isWithinInterval,
  setHours,
  setMinutes,
  startOfDay,
  subDays,
} from 'date-fns'
import {View} from '../components/nav/Navigation'
import {
  DayHours,
  DefaultRecourse,
  FieldProps,
  ProcessedEvent,
  ResourceFields,
  SchedulerProps,
  ConvertedHoursOfOperation,
  DailyAllowableHours,
  DictAllowableHours,
  Position,
} from '../types'
import {StateEvent} from '../components/views/Editor'
import {BaseEmployeeModel, LaborShiftModel} from '../core/_models'
import {boolean} from 'yup'
import {WeekProps} from '../components/views/Week'

export const getOneView = (state: Partial<SchedulerProps>): View => {
  // if (state.month) {
  //   return 'month'
  // } else

  if (state.week) {
    return 'week'
  } else if (state.day) {
    return 'timeline'
  }
  throw new Error('No views were selected')
}

export const getAvailableViews = (state: SchedulerProps) => {
  const views: View[] = []
  // if (state.month) {
  //   views.push('month')
  // }
  if (state.week) {
    views.push('week')
  }
  // if (state.day) {
  //   views.push('day')
  // }

  views.push('timeline')

  return views
}

export const arraytizeFieldVal = (field: FieldProps, val: any, event?: StateEvent) => {
  const arrytize = field.config?.multiple && !Array.isArray(event?.[field.name] || field.default)
  const value = arrytize ? (val ? [val] : []) : val
  const validity = arrytize ? value.length : value
  return {value, validity}
}

export const getResourcedEvents = (
  events: ProcessedEvent[],
  resource: DefaultRecourse,
  resourceFields: ResourceFields,
  fields: FieldProps[]
): ProcessedEvent[] => {
  const keyName = resourceFields.idField
  const resourceField = fields.find((f) => f.name === keyName)
  const isMultiple = !!resourceField?.config?.multiple
  const recousedEvents = []

  for (const event of events) {
    // Handle single select & multiple select accordingly
    const arrytize = isMultiple && !Array.isArray(event[keyName])
    const eventVal = arrytize ? [event[keyName]] : event[keyName]
    const isThisResource = isMultiple
      ? eventVal.includes(resource[keyName])
      : eventVal === resource[keyName]

    if (isThisResource) {
      recousedEvents.push({
        ...event,
        color: event.color || resource[resourceFields.colorField || ''],
      })
    }
  }

  return recousedEvents
}

export const traversCrossingEvents = (
  todayEvents: ProcessedEvent[],
  event: ProcessedEvent
): ProcessedEvent[] => {
  return todayEvents.filter(
    (e) =>
      e.event_id !== event.event_id &&
      (isWithinInterval(addMinutes(event.start, 1), {
        start: e.start,
        end: e.end,
      }) ||
        isWithinInterval(addMinutes(event.end, -1), {
          start: e.start,
          end: e.end,
        }) ||
        isWithinInterval(addMinutes(e.start, 1), {
          start: event.start,
          end: event.end,
        }) ||
        isWithinInterval(addMinutes(e.end, -1), {
          start: event.start,
          end: event.end,
        }))
  )
}

export const filterEventsBetween = (
  events: ProcessedEvent[],
  start: Date,
  end: Date,
  timeZone?: string,
  removeUncountable?: boolean,
  availablePositions?: Position[]
) => {
  const list: ProcessedEvent[] = []
  const positionDict: {[position: string]: boolean} = {}
  if (availablePositions) {
    availablePositions.forEach((item) => {
      positionDict[item.position] = item.is_countable
    })
  }
  for (let i = 0; i < events.length; i++) {
    if (removeUncountable && positionDict[events[i].position] === false) {
      continue
    }
    const event = convertEventTimeZone(events[i], timeZone)
    // check if there is an overlap
    // add 1 minute to start and end to avoid the case where the event is exactly at the start or end of the range
    if (
      isWithinInterval(addMinutes(event.start, 1), {
        start,
        end,
      }) ||
      isWithinInterval(addMinutes(event.end, -1), {
        start,
        end,
      }) ||
      isWithinInterval(addMinutes(start, 1), {
        start: event.start,
        end: event.end,
      }) ||
      isWithinInterval(addMinutes(end, -1), {
        start: event.start,
        end: event.end,
      })
    ) {
      list.push(event)
    }
  }

  return list
}

export const filterEventsBetweenByPosition = (
  events: ProcessedEvent[],
  start: Date,
  end: Date,
  timeZone?: string,
  position?: string
) => {
  const list: ProcessedEvent[] = []
  for (let i = 0; i < events.length; i++) {
    const event = convertEventTimeZone(events[i], timeZone)
    // check if there is an overlap
    // add 1 minute to start and end to avoid the case where the event is exactly at the start or end of the range
    if (
      isWithinInterval(addMinutes(event.start, 1), {
        start,
        end,
      }) ||
      isWithinInterval(addMinutes(event.end, -1), {
        start,
        end,
      }) ||
      isWithinInterval(addMinutes(start, 1), {
        start: event.start,
        end: event.end,
      }) ||
      isWithinInterval(addMinutes(end, -1), {
        start: event.start,
        end: event.end,
      })
    ) {
      list.push(event)
    }
  }

  return list
}

export const parseDateWithoutTimezone = (date: string) => {
  // parses the date with format yyyy-MM-dd
  const [year, month, day] = date.split('-')
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
}

export const calcMinuteHeight = (cellHeight: number, step: number) => {
  return cellHeight / step
  return Math.ceil(cellHeight) / step
}

export const calcCellHeight = (tableHeight: number, hoursLength: number) => {
  return Math.max(tableHeight / hoursLength, 60)
}

export const differenceInDaysOmitTime = (start: Date, end: Date) => {
  return differenceInDays(endOfDay(end), startOfDay(start))
}

export const filterTodayEvents = (events: ProcessedEvent[], today: Date, timeZone?: string) => {
  const list: ProcessedEvent[] = []
  for (let i = 0; i < events.length; i++) {
    const event = convertEventTimeZone(events[i], timeZone)
    if (!event.allDay) {
      list.push(event)
    }
  }
  return list.sort((a, b) => a.end.getTime() - b.end.getTime())
}

export const filterTodayEventsForResource = (
  events: ProcessedEvent[],
  today: Date,
  resource: DefaultRecourse,
  resourceFields: ResourceFields,
  fields: FieldProps[],
  timeZone?: string
) => {
  const list: ProcessedEvent[] = []
  for (let i = 0; i < events.length; i++) {
    const event = convertEventTimeZone(events[i], timeZone)
    const eventBusinessDay =
      typeof event.businessDay === 'string'
        ? parseDateWithoutTimezone(event.businessDay)
        : event.businessDay
    if (
      !event.allDay &&
      getResourcedEvents([event], resource, resourceFields, fields).length &&
      eventBusinessDay.getDate() === today.getDate()
    ) {
      list.push(event)
    }
  }
  return list.sort((a, b) => a.end.getTime() - b.end.getTime())
}

export const filterMultiDaySlot = (
  events: ProcessedEvent[],
  date: Date | Date[],
  timeZone?: string
) => {
  const list: ProcessedEvent[] = []
  for (let i = 0; i < events.length; i++) {
    const event = convertEventTimeZone(events[i], timeZone)
    let withinSlot = event.allDay || differenceInDaysOmitTime(event.start, event.end) > 0
    if (!withinSlot) continue
    if (Array.isArray(date)) {
      withinSlot = date.some((weekday) =>
        isWithinInterval(weekday, {
          start: startOfDay(event.start),
          end: endOfDay(event.end),
        })
      )
    } else {
      withinSlot = isWithinInterval(date, {
        start: startOfDay(event.start),
        end: endOfDay(event.end),
      })
    }

    if (withinSlot) {
      list.push(event)
    }
  }

  return list
}

export const convertEventTimeZone = (event: ProcessedEvent, timeZone?: string) => {
  return {
    ...event,
    start: getTimeZonedDate(event.start, timeZone),
    end: getTimeZonedDate(event.end, timeZone),
  }
}

export const getTimeZonedDate = (date: Date, timeZone?: string) => {
  return new Date(
    new Intl.DateTimeFormat('en-US', {
      dateStyle: 'short',
      timeStyle: 'medium',
      timeZone,
    }).format(date)
  )
}

export const getHoursOfOperationOnDay = (
  selectedDate: Date,
  HOURS_OF_OPERATIONS: ConvertedHoursOfOperation | null
) => {
  if (!HOURS_OF_OPERATIONS) {
    return [new Date(selectedDate), new Date(selectedDate)]
  }
  const day: number = selectedDate.getDay()
  const hoursOfOperation = HOURS_OF_OPERATIONS
  const {start, end} = hoursOfOperation[day as keyof typeof hoursOfOperation]

  const startHourDecimal = start - Math.floor(start)
  const newStart = setMinutes(setHours(new Date(selectedDate), start), startHourDecimal * 60)
  const endHourDecimal = end - Math.floor(end)
  const newEnd = setMinutes(setHours(new Date(selectedDate), end), endHourDecimal * 60)

  // if newEnd is before newStart, it means the hours of operation cross over midnight
  // so we need to add 24 hours to newEnd
  if (isBefore(newEnd, newStart) || isEqual(newEnd, newStart)) {
    return [newStart, addHours(newEnd, 24)]
  }

  return [newStart, newEnd]
}
export const getMinMaxHoursOfOperations = (
  HOURS_OF_OPERATIONS: ConvertedHoursOfOperation | null
): [DayHours, DayHours] => {
  if (!HOURS_OF_OPERATIONS) return [0, 24]

  const hoursOfOperation = HOURS_OF_OPERATIONS

  // add 24 hours to the end time if it is before the start time
  Object.keys(hoursOfOperation).forEach((key: any) => {
    const {start, end} = hoursOfOperation[key as keyof typeof hoursOfOperation]
    if (end < start) {
      hoursOfOperation[key as keyof typeof hoursOfOperation].end = end + 24
    }
  })

  const startHours = Object.values(hoursOfOperation).map((h) => h.start)
  const endHours = Object.values(hoursOfOperation).map((h) => h.end)

  const min = Math.min(...startHours) as DayHours
  const max = Math.max(...endHours) as DayHours

  return [min, (max >= 24 ? max - 24 : max) as DayHours]
}

export const toTitle = (text: string | undefined) => {
  if (!text) return ''
  return text
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const getPositionColor = (position: string): string => {
  const positionColorMap: {[key: string]: string} = {
    rgm: '#ab2d2d',
    'shift leader': '#58ab2d',
    sl: '#58ab2d',
    emp: '#6699cc',
  }
  return positionColorMap[position.toLowerCase()] || '#6699cc'
}

export const convertBaseEmployeeToResource = (employee: BaseEmployeeModel): DefaultRecourse => {
  return {
    employee_id: employee.id,
    title: toTitleCase(employee.first_name) + ' ' + toTitleCase(employee.last_name),
    position: employee.position,
    color: getPositionColor(employee.position),
  }
}

export const convertBaseEmployeesToResources = (
  employees: BaseEmployeeModel[]
): DefaultRecourse[] => {
  return employees.filter((e) => e.hide_in_schedule !== true).map(convertBaseEmployeeToResource)
}

export const convertBaseEmployeeToAssigneeOption = (employee: BaseEmployeeModel) => {
  return {
    id: employee.id,
    text: toTitleCase(employee.first_name) + ' ' + toTitleCase(employee.last_name),
    value: employee.id,
  }
}

export const convertBaseEmployeesToAssigneeOptions = (employees: BaseEmployeeModel[]) => {
  return employees.filter((e) => e.hide_in_schedule !== true).map(convertBaseEmployeeToAssigneeOption)
}

export const convertLaborShiftToEvent = (shift: LaborShiftModel): ProcessedEvent => {
  return {
    event_id: shift.id,
    title: shift.shift_role,
    position: shift.shift_role,
    start: new Date(shift.start_time),
    businessDay: shift.date_key,
    end: new Date(shift.end_time),
    allDay: false,
    employee_id: shift.employee_id,
    color: getPositionColor(shift.shift_role),
  }
}

export const convertLaborShifts = (shifts: LaborShiftModel[]): ProcessedEvent[] => {
  return shifts.map(convertLaborShiftToEvent)
}

export const extractEmployeeHours = (events: ProcessedEvent[]) => {
  const employeeHours: Map<number, number> = new Map()
  events.forEach((event) => {
    const hours = differenceInMinutes(event.end, event.start) / 60
    const currentHours = employeeHours.get(event.employee_id) || 0
    employeeHours.set(event.employee_id, currentHours + hours)
  })
  return employeeHours
}

export const extractHoursByDay = (events: ProcessedEvent[]) => {
  const hoursByDay: Map<string, number> = new Map() // for whole week key is 'week' for others it is string of date with 'yyyy-MM-dd' format
  const rgmHoursByDay: Map<string, number> = new Map() // for whole week key is 'week' for others it is string of date with 'yyyy-MM-dd' format
  events.forEach((event) => {
    const hours = differenceInMinutes(event.end, event.start) / 60
    const currentHours = hoursByDay.get('week') || 0
    hoursByDay.set('week', currentHours + hours)
    const date = format(event.start, 'yyyy-MM-dd')
    const currentHoursForDate = hoursByDay.get(date) || 0
    hoursByDay.set(date, currentHoursForDate + hours)
    if (event.position === 'rgm') {
      const rgmHours = rgmHoursByDay.get('week') || 0
      rgmHoursByDay.set('week', rgmHours + hours)
      const rgmHoursForDate = rgmHoursByDay.get(date) || 0
      rgmHoursByDay.set(date, rgmHoursForDate + hours)
    }
  })

  return [hoursByDay, rgmHoursByDay]
}

export const extractLaborCostByDay = (events: ProcessedEvent[], employees: BaseEmployeeModel[]) => {
  const laborCostByDay: Map<string, number> = new Map() // for whole week key is 'week' for others it is string of date with 'yyyy-MM-dd' format
  events.forEach((event) => {
    const employee = employees.find((e) => e.id === event.employee_id)
    if (!employee) return
    const hours = differenceInMinutes(event.end, event.start) / 60
    const currentCost = laborCostByDay.get('week') || 0
    laborCostByDay.set('week', currentCost + employee.pay_amount * hours)
    const date = format(event.start, 'yyyy-MM-dd')
    const currentCostForDate = laborCostByDay.get(date) || 0
    laborCostByDay.set(date, currentCostForDate + employee.pay_amount * hours)
  })

  return laborCostByDay
}
interface HoursOfOperation {
  operation_day_name: string
  start_time_for_eval: string
  end_time_for_eval: string
}

export type WeekDays = 0 | 1 | 2 | 3 | 4 | 5 | 6

export const DAYS_OF_WEEK: {[key: string]: WeekDays} = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
}

export const convertHoursOfOperations = (
  hoursOfOperations: HoursOfOperation[]
): ConvertedHoursOfOperation => {
  const convertedHours: ConvertedHoursOfOperation = {}

  hoursOfOperations.forEach((h) => {
    const dayStr = h.operation_day_name.toLowerCase()
    const dayNum = DAYS_OF_WEEK[dayStr]

    const startTime = h.start_time_for_eval
    const endTime = h.end_time_for_eval

    const startHours = parseInt(startTime.split(':')[0])
    const startMinutes = parseInt(startTime.split(':')[1])
    const start = startHours + startMinutes / 60

    const endHours = parseInt(endTime.split(':')[0])
    const endMinutes = parseInt(endTime.split(':')[1])
    let end = endHours + endMinutes / 60
    if (end <= start) {
      end += 24
    }

    convertedHours[dayNum] = {start, end}
  })

  return convertedHours
}

export const getAllowableValues = (
  allowableHoursDaily: DailyAllowableHours | null,
  selectedDate: Date,
  selectedPosition: string,
  hours: Date[]
) => {
  const dayOfWeek = selectedDate.toLocaleString('en-US', {weekday: 'long'}).toLowerCase()
  const position = selectedPosition === 'cashier' ? 'emp' : selectedPosition
  if (allowableHoursDaily) {
    const hoursData = allowableHoursDaily[dayOfWeek]
    let allowableValues: DictAllowableHours[] = []
    hours.forEach((hour) => {
      if (!hoursData) {
        allowableValues.push({number_of_employees: 0, is_strict: false})
        return
      }
      const hourData = hoursData[format(hour, 'HH:mm:ss')]
      if (hourData === undefined) {
        allowableValues.push({number_of_employees: 0, is_strict: false})
      } else {
        if (position === 'all') {
          const times = Object.values(hourData).map((d) => d.number_of_employees)
          const stricts = Object.values(hourData).map((d) => d.is_strict)
          const positionData = Object.values(times).reduce((acc, curr) => acc + curr, 0)

          allowableValues.push({
            number_of_employees: positionData || 0,
            is_strict: stricts.some((x) => x === true) || false,
          })
        } else {
          if (hourData[position] === undefined) {
            allowableValues.push({number_of_employees: 0, is_strict: false})
          } else {
            const positionData = hourData[position]['number_of_employees']
            const isStrict = hourData[position]['is_strict']
            allowableValues.push({
              number_of_employees: positionData || 0,
              is_strict: isStrict || false,
            })
          }
        }
      }
    })
    return allowableValues
  }
  return []
}

export const GetOldPageUrl = (url: string) => {
  return process.env.REACT_APP_OLD_FLASK_APP_URL + url
}

export const toTitleCase = (str: string) => {
  if (str === null || str === undefined) return str

  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const convertAvailablePositionsToOptions = (positions: Position[]) => {
  return positions.map((p, index) => ({
    value: p.position,
    text: toTitleCase(p.position),
    id: index,
  }))
}

export const getBusinessDay = (date: Date, hoursOfOperations: ConvertedHoursOfOperation | null) => {
  if (!hoursOfOperations) return date
  const dayOfWeek = date.getDay()
  const hours = hoursOfOperations[dayOfWeek]
  if (hours) {
    const start = hours.start
    const end = hours.end
    const dateHours = date.getHours() + date.getMinutes() / 60
    if (dateHours >= start && dateHours < end) {
      return date
    } else if (dateHours < start) {
      return subDays(date, 1)
    } else {
      return addDays(date, 1)
    }
  } else {
    return date
  }
}

export const getOverTimeHours = (events: ProcessedEvent[]) => {
  // If the employee has worked more than 40 hours in a week, the employee is eligible for overtime pay. Return {emp: ot_hours} object
  const employeeHours = extractHoursByEmployee(events)
  const employeeOvertimeHours: {[key: string]: number} = {}
  employeeHours.forEach((hours, employeeId) => {
    if (hours > 40) {
      employeeOvertimeHours[employeeId] = hours - 40
    }
  })
  return employeeOvertimeHours
}

export const extractHoursByEmployee = (events: ProcessedEvent[]) => {
  const hoursByEmployee: Map<string, number> = new Map()
  events.forEach((event) => {
    const hours = differenceInMinutes(event.end, event.start) / 60
    const currentHours = hoursByEmployee.get(event.employee_id) || 0
    hoursByEmployee.set(event.employee_id, currentHours + hours)
  })

  return hoursByEmployee
}

export const weekEquality = (week1: WeekProps | null, week2: WeekProps | null) => {
  if (!week1 || !week2) return false
  // compare weekdays
  if (week1.weekDays.length !== week2.weekDays.length) {
    return false
  }
  for (let i = 0; i < week1.weekDays.length; i++) {
    if (week1.weekDays[i] !== week2.weekDays[i]) return false
  }

  return (
    week1.weekStartOn === week2.weekStartOn &&
    week1.startHour === week2.startHour &&
    week1.endHour === week2.endHour &&
    week1.step === week2.step &&
    week1.navigation === week2.navigation &&
    week1.disableGoToDay === week2.disableGoToDay
  )
}
