/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect, useContext, useMemo} from 'react'
import {useIntl} from 'react-intl'

import {PageLink, PageTitle, useLayout} from '../../../_fds/layout/core'
import {LaborSchedulerHeader} from './LaborSchedulerHeader'
import {Scheduler} from './components/Scheduler'
import {EVENTS, RECOURCES} from './events'
import {Overview} from './components/Overview'
import {TeamMembers} from './components/TeamMembers'
import {WeeklySummaryProvider} from './context/WeeklySummaryContext'
import {
  getWeeklyScheduleSummary,
  getLaborScheduleActual,
  createShift,
  getWeatherForecast,
  deleteShift,
  updateShift,
  getHoursOfOperations,
  getWeeklyShifts,
  getEmployees,
  getLaborPositions,
  getAllowableHoursDaily,
  getScheduleDaySlotStats,
} from './core/_requests'
import {
  WeeklySummaryModel,
  LaborScheduleActualModel,
  GroupModel,
  ScheduleDaySlotStatsModel,
} from './core/_models'

import {
  convertAvailablePositionsToOptions,
  convertBaseEmployeesToAssigneeOptions,
  convertBaseEmployeesToResources,
  convertHoursOfOperations,
  convertLaborShifts,
  DAYS_OF_WEEK,
  extractEmployeeHours,
  extractHoursByDay,
  extractLaborCostByDay,
  filterEventsBetween,
  getAllowableValues,
  getBusinessDay,
  getHoursOfOperationOnDay,
  getMinMaxHoursOfOperations,
  toTitle,
  WeekDays,
} from './helpers/generals'
import {Navigate, Routes, Route, Outlet, Link, useLocation} from 'react-router-dom'
import LaborScheduleActualContext, {
  LaborScheduleActualProvider,
} from './context/LaborScheduleActualContext'
import SelectedLocationIdContext from './context/SelectedLocationContext'
import LoadingStateContext from './context/LoadingContext'
import {KTSVG} from '../../../_fds/helpers'

import {getLocations} from '../scheduler/core/_requests'
import {Navigation} from './components/nav/Navigation'
import {Form} from 'react-bootstrap'
import {useStore} from './store'
import Editor from './components/views/Editor'
import {useAuth} from '../auth'
import {EventActions, ProcessedEvent, SchedulerProps} from './types'
import {addDays, addMinutes, eachMinuteOfInterval, getWeek, startOfWeek} from 'date-fns'
import {useToast} from '../../../_fds/layout/components/toast-messages'
import {ScheduleCompletionContainer} from './components/common/ScheduleCompletionContainer'
import {SchedulerDropdown} from './components/SchedulerDropdown'
import {FdsModal} from './components/common/FdsModal'
import {fi} from 'date-fns/locale'
import {LaborSchedulerHeaderTest} from './LaborSchedulerHeaderTest'

const laborSchedulingBreadCrumbs: Array<PageLink> = [
  {
    title: 'Labor Scheduling',
    path: '/labor-scheduling/scheduling/schedule',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

type CustomToolbarProps = {
  locations: GroupModel[] | null
  scheduleStatusBadge: JSX.Element
  laborScheduleActual: LaborScheduleActualModel | null
  setLaborScheduleActual: (laborSchedule: LaborScheduleActualModel | null) => void
  selectedLocationId: number | null
  setSelectedLocationId: (id: number | null) => void
}

const CustomToolbarElements: FC<CustomToolbarProps> = ({
  locations,
  scheduleStatusBadge,
  laborScheduleActual,
  setLaborScheduleActual,
  selectedLocationId,
  setSelectedLocationId,
}) => {
  return (
    <>
      <div
        className='d-flex flex-row flex-grow-1 flex-wrap justify-content-between'
        style={{marginLeft: '10px'}}
      >
        <div className='d-flex flex-wrap align-items-center '>
          {/* begin::Label */}

          <div className='me-2 my-2'>{scheduleStatusBadge}</div>
        </div>
        <div className='d-flex flex-wrap align-items-center '>
          {/* begin::Label */}
          <Form.Label className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block'>
            Store:
          </Form.Label>
          {/* end::Label */}
          {/* begin::Select */}
          <Form.Select
            className='form-select form-select-sm w-125px form-select-solid me-2'
            placeholder='Store'
            value={selectedLocationId || undefined}
            onChange={(e) => setSelectedLocationId(Number(e.target.value))}
            key={selectedLocationId}
          >
            {locations &&
              locations.map((location) => (
                <option key={location.location_id} value={location.location_id}>
                  {location.name}
                </option>
              ))}
          </Form.Select>
          {/* end::Select */}
          {/* begin::Actions */}
          <Navigation />
          {/* end::Actions */}
          <div className='vr mx-2'></div>
          <div className='me-2'>
            <button
              className='btn btn-sm btn-bg-light btn-active-color-primary'
              data-fds-menu-trigger='click'
              data-fds-menu-placement='bottom-end'
              data-fds-menu-flip='top-end'
            >
              Options
              <i className='bi bi-caret-down-fill fs-6'></i>
            </button>
            <SchedulerDropdown
              laborScheduleActual={laborScheduleActual}
              setLaborScheduleActual={setLaborScheduleActual}
            />
          </div>
        </div>
      </div>
    </>
  )
}

function CreateSchedulePage() {
  const {currentUser} = useAuth()
  const {
    selectedLocationId,
    dialog,
    resources,
    events,
    hoursOfOperations,
    view,
    selectedDate,
    fields,
  } = useStore()
  const {showToast} = useToast()
  const [minStartHour, maxEndHour] = getMinMaxHoursOfOperations(hoursOfOperations)

  const handleConfirm = async (
    event: ProcessedEvent,
    action: EventActions
  ): Promise<ProcessedEvent> => {
    return new Promise(async (res, rej) => {
      try {
        const createdShift = await createShift(event, event.businessDay) // Call createShift with await to handle potential errors
        setTimeout(() => {
          res({
            ...event,
            event_id: createdShift?.data?.id,
            businessDay: event.businessDay,
          })

          showToast('Shift Created', 'Shift created successfully!', 'success')
        }, 10)
      } catch (error) {
        showToast('Shift Creation Failed', 'Unable to create shift!', 'danger')
        setTimeout(() => {}, 10)
        rej(error)
      }
    })
  }
  const handleDelete = async (id: number): Promise<number> => {
    return new Promise(async (res, rej) => {
      try {
        await deleteShift(id)
        res(id)
        showToast('Shift Deleted', 'Shift deleted successfully!', 'success')
      } catch (error) {
        showToast('Shift Deletion Failed', 'Unable to delete shift!', 'danger')
        rej(error)
      }
    })
  }
  const handleEventDrop = async (
    time: Date,
    businessDay: Date,
    updated: ProcessedEvent
  ): Promise<ProcessedEvent> => {
    return new Promise(async (res, rej) => {
      try {
        await updateShift(updated, businessDay)
        setTimeout(() => {
          res(updated)
        }, 10)
        showToast('Shift Updated', 'Shift updated successfully!', 'success')
      } catch (error) {
        showToast('Shift Update Failed', 'Unable to update shift!', 'danger')
        rej(error)
      }
    })
  }
  const handleEventClick = async (event: ProcessedEvent) => {}

  const location = useLocation()

  const createdPage = useMemo(() => {
    // console.log('CREATED PAGE USE MEMO', location)
    return (
      <Routes>
        <Route
          element={
            <>
              <LaborSchedulerHeader />
              {dialog && <Editor />}
              <Outlet />
            </>
          }
        >
          <Route
            path='schedule'
            element={
              <>
                {/* <FdsSelectBox value={selectedLocationId} 
                onChange=((newValue: any) =>  setselectedLocationId(newValue))
                options={locations} label='test' labelKey='text' /> */}

                <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                  <Scheduler
                    events={events}
                    selectedLocationId={selectedLocationId}
                    dialogMaxWidth='sm'
                    editable={true}
                    deletable={true}
                    draggable={true}
                    height={150}
                    selectedDate={selectedDate}
                    view={view}
                    onConfirm={handleConfirm}
                    onDelete={handleDelete}
                    onEventDrop={handleEventDrop}
                    onEventClick={handleEventClick}
                    week={{
                      weekDays: [0, 1, 2, 3, 4, 5, 6],
                      weekStartOn: DAYS_OF_WEEK[currentUser?.company?.week_start || 'monday'] || 1,
                      startHour: minStartHour,
                      endHour: maxEndHour,
                      step: 30,
                    }}
                    month={{
                      weekDays: [0, 1, 2, 3, 4, 5, 6],
                      weekStartOn: DAYS_OF_WEEK[currentUser?.company?.week_start || 'monday'] || 1,
                      startHour: 7,
                      endHour: 15,
                    }}
                    navigation={true}
                    navigationPickerProps={{
                      shouldDisableDate(day) {
                        return false
                      },
                    }}
                    resources={resources}
                    resourceFields={{
                      idField: 'employee_id',
                      textField: 'title',
                      subTextField: 'position',
                      avatarField: 'avatar',
                      colorField: 'color',
                    }}
                    resourceViewMode='tabs'
                    fields={fields}
                  />
                </div>
              </>
            }
          />
          <Route
            path='overview'
            element={
              <>
                <Overview />
              </>
            }
          />
          <Route
            path='team_members'
            element={
              <>
                <TeamMembers />
              </>
            }
          />
          <Route index element={<Navigate to='/labor-scheduling/scheduling/schedule' />} />
        </Route>
      </Routes>
    )
  }, [events, resources, selectedLocationId, dialog, selectedDate, view])

  return createdPage
}

const LaborSchedulingWrapper: FC = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {setToolbarType, setCustomToolbarElements} = useLayout()
  const [laborSchedule, setLaborSchedule] = useState<LaborScheduleActualModel | null>(null)
  const [weeklySummary, setWeeklySummary] = useState<WeeklySummaryModel | null>(null)
  const [weatherForecast, setWeatherForecast] = useState<string | null>(null)
  const [locations, setLocations] = useState<GroupModel[] | null>(null)
  const {
    handleState,
    handleMultipleStates,
    events,
    selectedLocationId,
    selectedDate,
    selectedWeekStart,
    selectedPosition,
    employees,
    week,
    view,
    hoursOfOperations,
    allowableHoursDaily,
    resources,
    availablePositions,
  } = useStore()
  const [isSchedulingEnabled, setIsSchedulingEnabled] = useState(true)

  const [minStartHour, maxEndHour] = getMinMaxHoursOfOperations(hoursOfOperations)

  const _setSelectedLocationId = (locationId: number | null) => {
    handleState(locationId, 'selectedLocationId')
  }

  const checkStritclyWrongSchedule = () => {
    const {weekStartOn, weekDays, step} = week!
    const _weekStart = startOfWeek(selectedDate, {weekStartsOn: weekStartOn})
    const daysList = weekDays.map((d) => addDays(_weekStart, d))
    let tempStriclyWrongSchedule = false
    daysList.forEach((day) => {
      const [dayStart, dayEnd] = getHoursOfOperationOnDay(day, hoursOfOperations)

      const dayEvents = events.filter(
        (x) => getBusinessDay(x.start, hoursOfOperations).getDate() === day.getDate()
      )

      const START_TIME = dayStart
      const END_TIME = addMinutes(dayEnd, -30)
      const hours = eachMinuteOfInterval(
        {
          start: START_TIME,
          end: END_TIME,
        },
        {step: step}
      )

      const allowableValues = getAllowableValues(allowableHoursDaily, day, selectedPosition, hours)

      let recousedEvents =
        selectedPosition === 'all'
          ? dayEvents
          : dayEvents.filter((e) =>
              resources
                .filter((emp) => emp.position === selectedPosition)
                .some((emp) => emp.employee_id === e.employee_id)
            )

      if (allowableValues.length !== 0) {
        hours.map((h, i) => {
          const headCountsUsed = filterEventsBetween(
            recousedEvents,
            h,
            addMinutes(h, step),
            undefined,
            true,
            availablePositions
          ).length
          const maxHeadCounts = allowableValues[i].number_of_employees
          const minHeadCounts = allowableValues[i].number_of_employees

          if (
            (headCountsUsed > maxHeadCounts || headCountsUsed < minHeadCounts) &&
            allowableValues[i].is_strict
          ) {
            tempStriclyWrongSchedule = true
          }
        })
      }
    })
    return tempStriclyWrongSchedule
  }

  const _setEditorFields = (resourceOptions: any, availablePositions: any) => {
    const fields = [
      {
        name: 'employee_id',
        type: 'select',
        config: {label: 'Assignee', required: true},
        options: resourceOptions,
      },
      {
        name: 'position',
        type: 'select',
        config: {label: 'Position', required: true},
        // default: [1],
        options: convertAvailablePositionsToOptions(availablePositions),
      },
    ]
    return fields
  }

  const calculateScheduleEnabling = (_laborScheduleActual: LaborScheduleActualModel) => {
    // if selected date's week is before current week or after 4 weeks from current week, disable scheduling where weekStartOn is 2
    const currentWeek = getWeek(new Date(), {weekStartsOn: week?.weekStartOn})
    const selectedWeek = getWeek(selectedDate, {weekStartsOn: week?.weekStartOn})
    const enableScheduling =
      (currentUser?.role?.name && ['Creator', 'Admin', 'DM'].includes(currentUser?.role?.name)) ||
      (selectedWeek >= currentWeek &&
        selectedWeek <= currentWeek + 3 &&
        ['open', 'in_progress'].includes(_laborScheduleActual?.workflow_status || ''))

    /* console.log(
      selectedWeek >= currentWeek,
      selectedWeek <= currentWeek + 3,
      ['open', 'in_progress'].includes(_laborScheduleActual?.workflow_status || ''),
      _laborScheduleActual?.workflow_status
    ) */

    let enabled = new Map<string, any>([
      ['draggable', true],
      ['deletable', true],
      ['editable', true],
    ])

    if (enableScheduling) {
      setIsSchedulingEnabled(true)
    } else {
      setIsSchedulingEnabled(false)
      enabled = new Map<string, any>([
        ['draggable', false],
        ['deletable', false],
        ['editable', false],
      ])
    }

    return enabled
  }

  const getScheduleStatusBadge = useMemo(() => {
    const status = laborSchedule?.workflow_status
    let colorClass = 'badge-secondary'
    let icon = '/media/icons/duotune/general/gen016.svg'
    let text = 'Open'

    if (status === 'in_progress') {
      colorClass = 'badge-light-warning'
      icon = '/media/icons/duotune/general/gen013.svg'
      text = 'In Progress'
    } else if (status === 'approved') {
      colorClass = 'badge-light-success'
      icon = '/media/icons/duotune/arrows/arr016.svg'
      text = 'Approved'
    } else if (status === 'awaiting_approval') {
      colorClass = 'badge-light-primary'
      icon = '/media/icons/duotune/general/gen015.svg'
      text = 'Awaiting Approval'
    }
    return (
      <span className='align-items-center text-dark mb-2 ml-5'>
        <span className={`badge ${colorClass} fs-9 fw-bold`}>
          <KTSVG path={icon} className='svg-icon-7 me-1 svg-icon-dark' />
          {toTitle(laborSchedule?.workflow_status)}
        </span>
      </span>
    )
  }, [laborSchedule, events])

  useEffect(() => {
    const _employeeHours = extractEmployeeHours(events)
    const [_dailyHours, _dailyRGMHours] = extractHoursByDay(events)
    const _dailyLaborCost = extractLaborCostByDay(events, employees)

    var states = new Map<string, any>([
      ['employeeHours', _employeeHours],
      ['dailyHours', _dailyHours],
      ['dailyRGMHours', _dailyRGMHours],
      ['dailyLaborCost', _dailyLaborCost],
    ])

    if (hoursOfOperations !== null) {
      const _strictlyWrongSchedule = checkStritclyWrongSchedule()

      states.set('strictlyWrongSchedule', _strictlyWrongSchedule)
    }

    handleMultipleStates(states)
  }, [events])

  useEffect(() => {
    document.body.setAttribute('data-fds-app-toolbar-fixed', 'true')
    const _setLocations = async () => {
      const locations = await getLocations()
      setLocations(locations)
      if (selectedLocationId === null || selectedLocationId === undefined) {
        _setSelectedLocationId(locations && locations[0].location_id)
      }

      const customToolbarElements = CustomToolbarElements({
        locations,
        scheduleStatusBadge: getScheduleStatusBadge,
        laborScheduleActual: laborSchedule,
        setLaborScheduleActual: setLaborSchedule,
        selectedLocationId: selectedLocationId,
        setSelectedLocationId: _setSelectedLocationId,
      })

      setCustomToolbarElements(customToolbarElements)
    }
    _setLocations()

    setToolbarType('extended')
  }, [laborSchedule])

  // locationId change effect
  useEffect(() => {
    const _getPositions = async () => {
      const positions = await getLaborPositions(selectedLocationId)
      return positions
    }

    const _getEmployees = async () => {
      const employees = await getEmployees(selectedLocationId, selectedPosition)
      const resourceOptions = convertBaseEmployeesToAssigneeOptions(employees)

      return {
        employees: employees,
        resourceOptions: resourceOptions,
      }
    }

    const _getHoursOfOperations = async () => {
      const hoursOfOperations = await getHoursOfOperations(selectedLocationId)

      const convertedHoursOfOperation = convertHoursOfOperations(hoursOfOperations)

      const [minStartHour, maxEndHour] = getMinMaxHoursOfOperations(convertedHoursOfOperation)
      const newWeek = {
        disableGoToDay: false,
        weekDays: [0, 1, 2, 3, 4, 5, 6] as WeekDays[],
        weekStartOn: DAYS_OF_WEEK[currentUser?.company?.week_start || 'monday'] || (1 as WeekDays),
        startHour: minStartHour,
        endHour: maxEndHour,
        step: 30,
        navigation: true,
      }
      return {
        hoursOfOperations: convertedHoursOfOperation,
        week: newWeek,
      }
    }

    const _getAllowableHours = async () => {
      const allowableHours = await getAllowableHoursDaily(selectedLocationId)
      return allowableHours
    }

    async function fetchData() {
      const [positions, employeesAndResourceOptions, _hoursOfOperations, allowableHours] =
        await Promise.all([
          _getPositions(),
          _getEmployees(),
          _getHoursOfOperations(),
          _getAllowableHours(),
        ])

      const fields = _setEditorFields(events, positions)
      const newStates = new Map<string, any>([
        ['availablePositions', positions],
        ['employees', employeesAndResourceOptions.employees],
        ['resourceOptions', employeesAndResourceOptions.resourceOptions],
        ['hoursOfOperations', _hoursOfOperations.hoursOfOperations],
        ['week', _hoursOfOperations.week],
        ['allowableHoursDaily', allowableHours],
        ['fields', fields],
        ['resources', convertBaseEmployeesToResources(employeesAndResourceOptions.employees)],
      ])
      handleMultipleStates(newStates)
    }

    if (!selectedLocationId) return

    fetchData()
  }, [selectedLocationId])

  // locationId, week change effect
  useEffect(() => {
    const _getShifts = async () => {
      const shifts = await getWeeklyShifts(selectedDate, selectedLocationId)
      const events = convertLaborShifts(shifts)
      return events
    }

    async function fetchData() {
      const [shifts, _laborScheduleActual] = await Promise.all([
        _getShifts(),
        getLaborScheduleActual(selectedLocationId, selectedDate),
      ])
      setLaborSchedule(_laborScheduleActual)
      const states = calculateScheduleEnabling(_laborScheduleActual)
      states.set('events', shifts)
      states.set('selectedLaborScheduleId', _laborScheduleActual?.id)
      handleMultipleStates(states)
    }

    if (!selectedLocationId) return

    fetchData()
  }, [selectedLocationId, selectedWeekStart])

  // locationId, week, events change effect
  useEffect(() => {
    async function fetchData() {
      const [_weeklyScheduleSummary, _scheduleDaySlotStats] = await Promise.all([
        getWeeklyScheduleSummary(selectedLocationId, selectedDate),
        getScheduleDaySlotStats(selectedLocationId, selectedDate),
      ])
      setWeeklySummary(_weeklyScheduleSummary)
      handleState(_scheduleDaySlotStats, 'scheduleDaySlotStats')
    }

    if (!selectedLocationId) return

    fetchData()
  }, [events])

  // selectedDate, selectedLocationId change effect
  useEffect(() => {
    async function fetchData() {
      // const _weatherForecast = await getWeatherForecast(selectedLocationId, selectedDate)
      setWeatherForecast('sunny')
      const [_scheduleDaySlotStats] = await Promise.all([
        getScheduleDaySlotStats(selectedLocationId, selectedDate),
      ])
      handleState(_scheduleDaySlotStats, 'scheduleDaySlotStats')

      const _selectedWeekStart = startOfWeek(selectedDate, {
        weekStartsOn: week?.weekStartOn,
      })
      handleState(_selectedWeekStart, 'selectedWeekStart')
    }

    if (!selectedLocationId) return

    fetchData()
  }, [selectedLocationId, selectedDate])

  const wrapper = useMemo(() => {
    return (
      <>
        <LaborScheduleActualProvider
          value={{laborScheduleActual: laborSchedule, setLaborScheduleActual: setLaborSchedule}}
        >
          <WeeklySummaryProvider value={weeklySummary}>
            <PageTitle breadcrumbs={laborSchedulingBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.CREATE_SCHEDULE'})}
            </PageTitle>
            <CreateSchedulePage />
          </WeeklySummaryProvider>
        </LaborScheduleActualProvider>
      </>
    )
  }, [laborSchedule, weeklySummary, view])

  return wrapper
}

export {LaborSchedulingWrapper}
