import enUS from 'date-fns/locale/en-US'
import {SchedulerProps} from '../types'
import {getOneView} from '../helpers/generals'
import {addDays} from 'date-fns'

const defaultMonth = {
  weekDays: [0, 1, 2, 3, 4, 5, 6],
  weekStartOn: 6,
  startHour: 9,
  endHour: 17,
  navigation: true,
  disableGoToDay: true,
}

const defaultWeek = {
  weekDays: [0, 1, 2, 3, 4, 5, 6],
  weekStartOn: 6,
  startHour: 9,
  endHour: 17,
  step: 60,
  navigation: true,
  disableGoToDay: false,
}

const defaultDay = {
  startHour: 9,
  endHour: 17,
  step: 60,
  navigation: true,
}

const defaultResourceFields = {
  idField: 'assignee',
  textField: 'text',
  subTextField: 'subtext',
  avatarField: 'avatar',
  colorField: 'color',
}

const defaultTranslations = (trans: Partial<SchedulerProps['translations']> = {}) => {
  const {navigation, form, event, ...other} = trans

  return {
    navigation: Object.assign(
      {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        timeline: 'Timeline',
        today: 'Today',
      },
      navigation
    ),
    form: Object.assign(
      {
        addTitle: 'Add Shift',
        editTitle: 'Edit Shift',
        confirm: 'Confirm',
        delete: 'Delete',
        cancel: 'Cancel',
      },
      form
    ),
    event: Object.assign(
      {
        title: 'Position',
        start: 'Shift start',
        end: 'Shift end',
        allDay: 'All Day',
      },
      event
    ),
    ...Object.assign({moreEvents: 'More...', loading: 'Loading...'}, other),
  }
}

const defaultViews = (props: Partial<SchedulerProps>) => {
  const {month, week, day} = props
  return {
    month: month !== null ? Object.assign(defaultMonth, month) : null,
    week: week !== null ? Object.assign(defaultWeek, week) : null,
    employeesWeek: week !== null ? Object.assign(defaultWeek, week) : null,
    day: day !== null ? Object.assign(defaultDay, day) : null,
    timeline: day !== null ? Object.assign(defaultDay, day) : null,
  }
}

export const defaultProps = (props: Partial<SchedulerProps>) => {
  const {month, week, day, translations, resourceFields, view, ...otherProps} = props
  const views = defaultViews(props)
  const defaultView = view || 'employeesWeek'
  const initialView = views[defaultView] ? defaultView : getOneView(views)
  const newDate = new Date()
  const nextWeek = addDays(newDate, 7)
  // newDate.setHours(0, 0, 0, 0)
  return {
    ...views,
    translations: defaultTranslations(translations),
    resourceFields: Object.assign(defaultResourceFields, resourceFields),
    view: initialView,
    ...Object.assign(
      {
        height: 600,
        navigation: true,
        selectedDate: nextWeek,
        disableViewNavigator: false,
        events: [],
        fields: [],
        loading: undefined,
        customEditor: undefined,
        onConfirm: undefined,
        onDelete: undefined,
        viewerExtraComponent: undefined,
        resources: [],
        recourseHeaderComponent: undefined,
        resourceViewMode: 'default',
        direction: 'ltr',
        dialogMaxWidth: 'md',
        locale: enUS,
        deletable: true,
        editable: true,
        hourFormat: '12',
        draggable: true,
      },
      otherProps
    ),
  }
}

export const initialStore = {
  ...defaultProps({}),
  initiateProps: () => {},
  dialog: false,
  selectedRange: undefined,
  selectedEvent: undefined,
  selectedResource: undefined,
  handleState: () => {},
  handleMultipleStates: () => {},
  getViews: () => [],
  triggerDialog: () => {},
  triggerLoading: () => {},
  handleGotoDay: () => {},
  confirmEvent: () => {},
  onDrop: () => {},
  selectedLocationId: null,
  selectedWeekStart: null,
  hoursOfOperations: null,
  allowableHoursDaily: null,
  selectedLocation: null,
  selectedLaborScheduleId: null,
  weatherForecast: null,
  scheduleDaySlotStats: null,
  resourceOptions: [],
  employeeHours: new Map<number, number>(),
  dailyHours: new Map<string, number>(),
  dailyRGMHours: new Map<string, number>(),
  dailyLaborCost: new Map<string, number>(),
  selectedPosition: 'all',
  availablePositions: [{position: 'all', is_countable: true}],
  searchString: '',
  employees: [],
  strictlyWrongSchedule: false,
}
